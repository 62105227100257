/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReviewFilledIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M10.816 1.91a1.019 1.019 0 0 0-1.632 0L6.507 5.523a1.01 1.01 0 0 1-.501.36L1.696 7.28a.994.994 0 0 0-.504 1.533l2.655 3.631a.99.99 0 0 1 .191.582l.014 4.478c.002.676.669 1.154 1.32.947l4.318-1.37c.202-.064.418-.064.62 0l4.317 1.37c.652.207 1.32-.271 1.321-.947l.014-4.478a.99.99 0 0 1 .191-.582l2.655-3.63a.994.994 0 0 0-.504-1.534l-4.31-1.397a1.01 1.01 0 0 1-.5-.359L10.815 1.91Z"
        />
    </svg>
);
const ForwardRef = forwardRef(ReviewFilledIcon);
export default ForwardRef;
